<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the user info. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadUserInfo"
    >
      Try Again
    </button>
  </template>

  <div
    v-else-if="status === 'loaded'"
    class="card"
  >
    <div
      v-if="userInfo.biography !== ''"
      class="biography"
      v-html="biographyHtml()"
    />

    <table
      v-if="userInfo.third_party.length"
      class="third-party-table"
    >
      <tr
        v-for="(entry, index) of userInfo.third_party"
        :key="`entry${index}`"
      >
        <th>{{ entry.name }}</th>
        <td>
          <a
            v-if="entry.url"
            :href="entry.url"
            target="_blank"
          >
            {{ entry.value }}
          </a>

          <template v-else>
            {{ entry.value }}
          </template>
        </td>
      </tr>
    </table>

    <template v-if="userData.username === user.username">
      You
    </template>

    <template v-else>
      {{ user.first_name }}
    </template>

    joined on {{ dateDisplay(userInfo.date_joined) }}
  </div>
</template>

<script lang="ts">
import linkify from 'linkifyjs/html';
import { defineComponent, PropType } from 'vue';
import { UserProfileInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<UserProfileInterface>,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    userInfo: {} as {
      // eslint-disable-next-line camelcase
      date_joined: string;
      biography: string;
      // eslint-disable-next-line camelcase
      third_party: Array<Record<string, string>>;
    },
  }),
  created() {
    this.loadUserInfo();
  },
  methods: {
    biographyHtml() {
      return linkify(
        this.linebreaksbr(this.userInfo.biography),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadUserInfo() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `users/${this.user.username}/info/`,
      });

      if (responseData.status === 200) {
        this.userInfo = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .biography {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray);
  }

  .third-party-table {
    margin-bottom: 1rem;
    table-layout: fixed;

    th,
    td {
      vertical-align: top;
    }

    th {
      width: 10.2em;
      text-align: right;
    }

    td {
      word-break: break-word;
    }
  }

  @media (min-width: 568px) {
    .third-party-table th {
      width: 15.3em;
    }
  }
</style>
