
import { defineComponent } from 'vue';
import Post from '@/components/posts/Post.vue';
import PostList from '@/components/posts/PostList.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import UserCalendar from '@/components/users/UserCalendar.vue';
import UserGameList from '@/components/users/UserGameList.vue';
import UserInfo from '@/components/users/UserInfo.vue';
import { CommentInterface } from '@/interfaces/posts';
import { UserProfileInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    Post,
    PostList,
    ProfileImage,
    UserCalendar,
    UserGameList,
    UserInfo,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).loadUser();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadUser();
    }, 0);
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    postId: {
      type: Number,
      required: false,
      default: 0,
    },
    commentId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    userStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    user: {} as UserProfileInterface,
    postStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    post: {},
    showCommentNotFoundMessage: false,
    invitationToken: '',
    togglingFollow: false,
  }),
  methods: {
    async loadPost() {
      this.postStatus = 'loading';

      const responseData = await this.api({
        url: `posts/${this.postId}/?user=${this.user.username}`,
      });

      if (responseData.status === 200) {
        const post = responseData.body;

        if (post.comments.length) {
          let previousPath = '';
          let index = 0;
          post.comments.forEach((comment: CommentInterface) => {
            const path = `${comment.path.join('-')}-`;

            if (index) {
              post.comments[index - 1].has_children = path.startsWith(previousPath);
            }

            previousPath = path;
            index += 1;
          });

          post.comments[index - 1].has_children = false;
        }

        this.post = post;
        this.postStatus = 'loaded';

        if (this.commentId) {
          const comment = post.comments.filter((c: CommentInterface) => c.id === this.commentId)[0];

          if (comment) {
            this.$nextTick(() => {
              (this.$refs.post as InstanceType<typeof Post>).navigateToCommentById(this.commentId);
            });
          } else {
            this.showCommentNotFoundMessage = true;
          }
        }
      } else if (responseData.status === 404) {
        this.postStatus = 'not_found';
      } else {
        this.postStatus = 'error';
      }
    },
    async loadUser() {
      if (this.userStatus === 'loaded' && this.user.username === this.username) {
        this.afterUserLoaded();
        return;
      }

      this.userStatus = 'loading';

      const responseData = await this.api({
        url: `users/${this.username}/`,
      });

      if (responseData.status === 200) {
        this.user = responseData.body;
        this.userStatus = 'loaded';
        this.afterUserLoaded();

        if (!this.loggedIn && this.user.ipg_status !== 'not_ipg') {
          const currentRoute = this.$router.currentRoute.value;
          const token = currentRoute.query.invitation;

          if (token !== undefined) {
            this.invitationToken = token as string;

            this.api({
              url: `business-portal/invitation_visited/?username=${this.username}&token=${token}`,
              method: 'POST',
            });
          }
        }
      } else if (responseData.status === 404) {
        this.userStatus = 'not_found';
      } else {
        this.userStatus = 'error';
      }
    },
    afterUserLoaded() {
      if (['post', 'comment'].includes(this.activeTab)) {
        this.loadPost();
      }
    },
    chatClickHandler() {
      const chatRoomName = [this.userData.id, this.user.chat_room_id as number].sort((a, b) => a - b).join('.');
      (this.$root as any).openChatRoom(chatRoomName);
    },
    async toggleFollow() {
      if (!this.togglingFollow) {
        this.togglingFollow = true;

        const responseData = await this.api({
          url: `users/${this.username}/toggle_follow/`,
          method: 'POST',
          json: { follow: !this.user.followed_by_user },
        });

        this.togglingFollow = false;

        if (responseData.status === 200) {
          this.user.followed_by_user = !this.user.followed_by_user;
        } else {
          const followOrUnfollow = this.user.followed_by_user ? 'Unfollow' : 'Follow';
          const title = `Failed to ${followOrUnfollow} This User`;
          let text;

          if (responseData.status === 404) {
            text = 'This user no longer exists.';
          } else {
            text = 'Please check your connection and try again.';
          }

          this.$swal(title, text);
        }
      }
    },
  },
});
