<template>
  <template v-if="userStatus === 'loaded'">
    <img
      v-if="user.cover_image"
      :src="user.cover_image"
      alt
      class="img-fluid cover-image"
    >

    <div class="horizontal-padding">
      <div class="below-cover-image">
        <profile-image
          class="profile-image"
          :user="user"
          :size="128"
          bordered
        />

        <div>
          <h1>{{ user.first_name }} {{ user.last_name }}</h1>

          <div v-if="loggedIn">
            <router-link
              v-if="userData.username === username"
              :to="{ name: 'settings_account' }"
              class="btn btn-outline-primary btn-sm"
            >
              Settings
            </router-link>

            <template v-else>
              <button
                type="button"
                class="btn btn-sm"
                :class="{
                  'btn-danger': user.followed_by_user,
                  'btn-outline-primary': !user.followed_by_user,
                }"
                :aria-label="`${user.followed_by_user ? 'Unf' : 'F'}ollow This User`"
                @click="toggleFollow"
              >
                <spinner v-if="togglingFollow" />

                <template v-else-if="user.followed_by_user">
                  Unfollow
                </template>

                <template v-else>
                  Follow
                </template>
              </button>

              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                aria-label="Chat with This User`"
                @click="chatClickHandler"
              >
                Chat
              </button>
            </template>
          </div>
        </div>
      </div>

      <alert
        v-if="!loggedIn && user.ipg_status !== 'not_ipg'"
        class="text-center"
      >
        {{ user.first_name }} is an Independent Professional Gamer and is ready
        to mentor you! Interested?

        <router-link
          :to="{
            name: 'signup',
            params: { username },
            query: invitationToken === '' ? {} : { invitation: invitationToken },
          }"
          class="alert-link"
        >
          Sign up today!
        </router-link>
      </alert>

      <nav
        class="navbar"
        aria-label="User Profile"
      >
        <ul>
          <li>
            <router-link :to="{ name: 'user_info', params: { username } }">
              Info
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user_profile', params: { username } }">
              Posts
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user_games', params: { username, listType: 'casual' } }">
              Games (Casual)
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'user_games', params: { username, listType: 'competitive' } }"
            >
              Games (Competitive)
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user_games', params: { username, listType: 'coach' } }">
              Coaching
            </router-link>
          </li>
          <li v-if="loggedIn && userData.username === username">
            <router-link :to="{ name: 'my_calendar' }">
              Calendar
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="content">
        <user-info
          v-if="activeTab === 'info'"
          :user="user"
        />

        <post-list
          v-else-if="activeTab === 'posts'"
          :user="user"
        />

        <template v-else-if="['post', 'comment'].includes(activeTab)">
          <spinner
            v-if="postStatus === 'loading'"
            preset="large"
          />

          <template v-else-if="postStatus === 'loaded'">
            <alert
              v-if="showCommentNotFoundMessage"
              variant="danger"
              dismissible
              @dismissed="showCommentNotFoundMessage = false"
            >
              That comment does not exist (it might have been deleted).
            </alert>

            <post
              ref="post"
              :post="post"
              @postDeleted="$router.push({ name: 'user_profile', params: { username } })"
            />
          </template>

          <template v-else-if="postStatus === 'error'">
            <alert variant="danger">
              An error occurred loading the post. Please check your connection
              and try again.
            </alert>

            <button
              type="button"
              class="btn btn-outline-primary"
              @click="loadPost"
            >
              Try Again
            </button>
          </template>

          <alert
            v-else-if="postStatus === 'not_found'"
            variant="danger"
          >
            <template v-if="loggedIn && userData.username === username">
              That post does not exist.
            </template>

            <template v-else>
              That post does not exist or you do not have permission to view
              it.
            </template>
          </alert>
        </template>

        <user-game-list
          v-else-if="activeTab.startsWith('games')"
          :key="activeTab"
          :user="user"
          :list-type="activeTab.slice(6)"
        />

        <user-calendar
          v-else-if="activeTab === 'calendar'"
        />
      </div>
    </div>
  </template>

  <div
    v-else
    class="horizontal-padding"
  >
    <spinner
      v-if="userStatus === 'loading'"
      preset="large"
    />

    <template v-else-if="userStatus === 'error'">
      <alert variant="danger">
        An error occurred loading the user. Please check your connection and
        try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadUser"
      >
        Try Again
      </button>
    </template>

    <alert
      v-else-if="userStatus === 'not_found'"
      variant="danger"
    >
      That user does not exist.
    </alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Post from '@/components/posts/Post.vue';
import PostList from '@/components/posts/PostList.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import UserCalendar from '@/components/users/UserCalendar.vue';
import UserGameList from '@/components/users/UserGameList.vue';
import UserInfo from '@/components/users/UserInfo.vue';
import { CommentInterface } from '@/interfaces/posts';
import { UserProfileInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    Post,
    PostList,
    ProfileImage,
    UserCalendar,
    UserGameList,
    UserInfo,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).loadUser();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadUser();
    }, 0);
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    postId: {
      type: Number,
      required: false,
      default: 0,
    },
    commentId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    userStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    user: {} as UserProfileInterface,
    postStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    post: {},
    showCommentNotFoundMessage: false,
    invitationToken: '',
    togglingFollow: false,
  }),
  methods: {
    async loadPost() {
      this.postStatus = 'loading';

      const responseData = await this.api({
        url: `posts/${this.postId}/?user=${this.user.username}`,
      });

      if (responseData.status === 200) {
        const post = responseData.body;

        if (post.comments.length) {
          let previousPath = '';
          let index = 0;
          post.comments.forEach((comment: CommentInterface) => {
            const path = `${comment.path.join('-')}-`;

            if (index) {
              post.comments[index - 1].has_children = path.startsWith(previousPath);
            }

            previousPath = path;
            index += 1;
          });

          post.comments[index - 1].has_children = false;
        }

        this.post = post;
        this.postStatus = 'loaded';

        if (this.commentId) {
          const comment = post.comments.filter((c: CommentInterface) => c.id === this.commentId)[0];

          if (comment) {
            this.$nextTick(() => {
              (this.$refs.post as InstanceType<typeof Post>).navigateToCommentById(this.commentId);
            });
          } else {
            this.showCommentNotFoundMessage = true;
          }
        }
      } else if (responseData.status === 404) {
        this.postStatus = 'not_found';
      } else {
        this.postStatus = 'error';
      }
    },
    async loadUser() {
      if (this.userStatus === 'loaded' && this.user.username === this.username) {
        this.afterUserLoaded();
        return;
      }

      this.userStatus = 'loading';

      const responseData = await this.api({
        url: `users/${this.username}/`,
      });

      if (responseData.status === 200) {
        this.user = responseData.body;
        this.userStatus = 'loaded';
        this.afterUserLoaded();

        if (!this.loggedIn && this.user.ipg_status !== 'not_ipg') {
          const currentRoute = this.$router.currentRoute.value;
          const token = currentRoute.query.invitation;

          if (token !== undefined) {
            this.invitationToken = token as string;

            this.api({
              url: `business-portal/invitation_visited/?username=${this.username}&token=${token}`,
              method: 'POST',
            });
          }
        }
      } else if (responseData.status === 404) {
        this.userStatus = 'not_found';
      } else {
        this.userStatus = 'error';
      }
    },
    afterUserLoaded() {
      if (['post', 'comment'].includes(this.activeTab)) {
        this.loadPost();
      }
    },
    chatClickHandler() {
      const chatRoomName = [this.userData.id, this.user.chat_room_id as number].sort((a, b) => a - b).join('.');
      (this.$root as any).openChatRoom(chatRoomName);
    },
    async toggleFollow() {
      if (!this.togglingFollow) {
        this.togglingFollow = true;

        const responseData = await this.api({
          url: `users/${this.username}/toggle_follow/`,
          method: 'POST',
          json: { follow: !this.user.followed_by_user },
        });

        this.togglingFollow = false;

        if (responseData.status === 200) {
          this.user.followed_by_user = !this.user.followed_by_user;
        } else {
          const followOrUnfollow = this.user.followed_by_user ? 'Unfollow' : 'Follow';
          const title = `Failed to ${followOrUnfollow} This User`;
          let text;

          if (responseData.status === 404) {
            text = 'This user no longer exists.';
          } else {
            text = 'Please check your connection and try again.';
          }

          this.$swal(title, text);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .cover-image {
    display: block;

    + div .below-cover-image {
      margin-top: -3rem;
    }
  }

  .below-cover-image {
    display: flex;
    margin-bottom: 1rem;

    > div:last-child {
      padding-top: 4rem;
    }

    h1 {
      max-width: 145px;
      font-size: 1.4rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .profile-image {
    align-self: start;
  }

  .content {
    margin: 0 auto;
    max-width: 500px;
  }

  @media (min-width: 480px) {
    .below-cover-image h1 {
      max-width: 320px;
    }
  }

  @media (min-width: 1024px) {
    .below-cover-image > div:last-child {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;

      div {
        margin-left: 0.5rem;
      }
    }
  }
</style>
