
import linkify from 'linkifyjs/html';
import { defineComponent, PropType } from 'vue';
import { UserProfileInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<UserProfileInterface>,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    userInfo: {} as {
      // eslint-disable-next-line camelcase
      date_joined: string;
      biography: string;
      // eslint-disable-next-line camelcase
      third_party: Array<Record<string, string>>;
    },
  }),
  created() {
    this.loadUserInfo();
  },
  methods: {
    biographyHtml() {
      return linkify(
        this.linebreaksbr(this.userInfo.biography),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadUserInfo() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `users/${this.user.username}/info/`,
      });

      if (responseData.status === 200) {
        this.userInfo = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});
