<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the games. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadGames"
    >
      Try Again
    </button>
  </template>

  <template v-else-if="status === 'loaded'">
    <template v-if="games.results.length">
      <div class="games">
        <router-link
          v-for="game of games.results"
          :key="`game${game.game.slug}`"
          :to="{ name: 'game', params: { gameSlug: game.game.slug } }"
          class="game"
        >
          <img
            v-if="game.game.cover_image_id"
            :src="`https://images.igdb.com/igdb/image/upload/t_thumb/${game.game.cover_image_id}.jpg`"
            alt
          >

          <div>
            <div>
              {{ game.game.name }}
              ({{ game.game.first_release_date.substring(0, 4) }})
            </div>

            <template v-if="listType === 'coach'">
              Coaches
            </template>

            <template v-else>
              Plays
            </template>

            on {{ toListSentence(game.platforms) }}
          </div>
        </router-link>
      </div>

      <div class="load-more-content">
        <button
          v-show="moreGamesStatus === 'idle' && games.next"
          ref="loadMoreGamesButton"
          type="button"
          class="btn btn-outline-primary load-more-games-button"
          @click="loadMoreGames"
        >
          Load More Games
        </button>

        <spinner
          v-if="moreGamesStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="moreGamesStatus === 'error'">
          <alert variant="danger">
            An error occurred while trying to load more games. Please check
            your connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadMoreGames"
          >
            Try Again
          </button>
        </template>
      </div>
    </template>

    <p
      v-else
      class="text-center"
    >
      <template v-if="userData.username === user.username">
        <template v-if="listType === 'coach'">
          To specify which games you're a coach for, search for a game using
          the search menu above. Once you're at the game's page, click
          "Coaches" and then click the "I'm a Coach for This Game" button.
        </template>

        <template v-else>
          To specify which games you play {{ listType }}ly, search for a game
          using the search menu above. Once you're at the game's page, click
          "{{ capitalizeWords(listType) }} Players" and then click the "I Play
          This Game {{ capitalizeWords(listType) }}ly" button.
        </template>
      </template>

      <template v-else-if="listType === 'coach'">
        {{ user.first_name }} has not specified which games they are a coach
        for.
      </template>

      <template v-else>
        {{ user.first_name }} has not specified which games they play {{ listType }}ly.
      </template>
    </p>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { CoachGameInterface, PlayerGameInterface } from '@/interfaces/games';
import { UserProfileInterface } from '@/interfaces/users';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<UserProfileInterface>,
      required: true,
    },
    listType: {
      type: String as PropType<'casual' | 'competitive' | 'coach'>,
      required: true,
      validator: (value: string) => [
        'casual',
        'competitive',
        'coach',
      ].includes(value),
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    games: {} as {
      next: string | null,
      previous: string | null,
      results: Array<CoachGameInterface | PlayerGameInterface>,
    },
    moreGamesStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  created() {
    this.loadGames();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadGames() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `users/${this.user.username}/game_list/?list_type=${this.listType}`,
      });

      if (responseData.status === 200) {
        this.games = responseData.body;
        this.status = 'loaded';

        if (this.games.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreGames();
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreGamesButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMoreGames() {
      this.moreGamesStatus = 'loading';

      const responseData = await this.api({
        url: this.games.next as string,
      });

      if (responseData.status === 200) {
        this.games.next = responseData.body.next;
        this.games.previous = responseData.body.previous;
        this.games.results = this.games.results.concat(responseData.body.results);
        this.moreGamesStatus = 'idle';
      } else {
        this.moreGamesStatus = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .games {
    border-radius: 6px;
    overflow: hidden;
  }

  .game {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    padding: 10px 16px;
    color: #fff;
    background-color: var(--gray-darker);
    transition: background-color 0.15s ease-in-out;
    word-wrap: break-word;

    @supports #{'\selector(*:focus-visible)'}  {
      &:focus {
        outline: 0;
      }

      &:focus-visible {
        background-color: var(--gray-dark);
      }
    }

    &:hover {
      background-color: var(--gray-dark);
      text-decoration: none;
    }

    + .game {
      border-top: 1px solid var(--gray-darkest);
    }
  }

  .load-more-content {
    margin-top: 1rem;
  }

  .load-more-games-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
